import { default as _91_46_46_46slug_93Vs8QR1oAhiMeta } from "/home/andrea/projects/abaco-chat/pages/[...slug].vue?macro=true";
import { default as chatc5K4GOISe4Meta } from "/home/andrea/projects/abaco-chat/pages/chat.vue?macro=true";
import { default as indexGSmhBJW0ekMeta } from "/home/andrea/projects/abaco-chat/pages/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93Vs8QR1oAhiMeta?.name ?? "slug",
    path: _91_46_46_46slug_93Vs8QR1oAhiMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93Vs8QR1oAhiMeta || {},
    alias: _91_46_46_46slug_93Vs8QR1oAhiMeta?.alias || [],
    redirect: _91_46_46_46slug_93Vs8QR1oAhiMeta?.redirect || undefined,
    component: () => import("/home/andrea/projects/abaco-chat/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: chatc5K4GOISe4Meta?.name ?? "chat",
    path: chatc5K4GOISe4Meta?.path ?? "/chat",
    meta: chatc5K4GOISe4Meta || {},
    alias: chatc5K4GOISe4Meta?.alias || [],
    redirect: chatc5K4GOISe4Meta?.redirect || undefined,
    component: () => import("/home/andrea/projects/abaco-chat/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: indexGSmhBJW0ekMeta?.name ?? "index",
    path: indexGSmhBJW0ekMeta?.path ?? "/",
    meta: indexGSmhBJW0ekMeta || {},
    alias: indexGSmhBJW0ekMeta?.alias || [],
    redirect: indexGSmhBJW0ekMeta?.redirect || undefined,
    component: () => import("/home/andrea/projects/abaco-chat/pages/index.vue").then(m => m.default || m)
  }
]